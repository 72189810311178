{
  "firstNight": [
    "DUSK",
    "lordoftyphon",
    "kazali",
    "apprentice",
    "barista",
    "bureaucrat",
    "thief",
    "boffin",
    "philosopher",
    "alchemist",
    "poppygrower",
    "yaggababble",
    "magician",
    "MINION",
    "snitch",
    "lunatic",
    "summoner",
    "DEMON",
    "king",
    "sailor",
    "marionette",
    "engineer",
    "preacher",
    "lilmonsta",
    "lleech",
    "poisoner",
    "widow",
    "courtier",
    "snakecharmer",
    "godfather",
    "devilsadvocate",
    "eviltwin",
    "witch",
    "cerenovus",
    "fearmonger",
    "harpy",
    "mezepheles",
    "pukka",
    "pixie",
    "huntsman",
    "damsel",
    "amnesiac",
    "washerwoman",
    "librarian",
    "investigator",
    "chef",
    "empath",
    "fortuneteller",
    "butler",
    "grandmother",
    "clockmaker",
    "dreamer",
    "seamstress",
    "steward",
    "knight",
    "noble",
    "balloonist",
    "shugenja",
    "villageidiot",
    "bountyhunter",
    "nightwatchman",
    "cultleader",
    "spy",
    "ogre",
    "highpriestess",
    "general",
    "chambermaid",
    "mathematician",
    "DAWN",
    "leviathan",
    "vizier"
  ],
  "otherNight": [
    "DUSK",
    "barista",
    "bureaucrat",
    "thief",
    "harlot",
    "bonecollector",
    "philosopher",
    "poppygrower",
    "sailor",
    "engineer",
    "preacher",
    "poisoner",
    "courtier",
    "innkeeper",
    "gambler",
    "snakecharmer",
    "monk",
    "devilsadvocate",
    "witch",
    "cerenovus",
    "pithag",
    "fearmonger",
    "harpy",
    "mezepheles",
    "scarletwoman",
    "summoner",
    "lunatic",
    "exorcist",
    "lycanthrope",
    "legion",
    "imp",
    "zombuul",
    "pukka",
    "shabaloth",
    "po",
    "fanggu",
    "nodashii",
    "vortox",
    "lordoftyphon",
    "vigormortis",
    "ojo",
    "alhadikhia",
    "lleech",
    "lilmonsta",
    "yaggababble",
    "kazali",
    "assassin",
    "godfather",
    "gossip",
    "acrobat",
    "hatter",
    "barber",
    "sweetheart",
    "sage",
    "banshee",
    "professor",
    "choirboy",
    "huntsman",
    "damsel",
    "amnesiac",
    "farmer",
    "tinker",
    "moonchild",
    "grandmother",
    "ravenkeeper",
    "empath",
    "fortuneteller",
    "undertaker",
    "dreamer",
    "flowergirl",
    "towncrier",
    "oracle",
    "seamstress",
    "juggler",
    "balloonist",
    "villageidiot",
    "king",
    "bountyhunter",
    "nightwatchman",
    "cultleader",
    "butler",
    "spy",
    "highpriestess",
    "general",
    "chambermaid",
    "mathematician",
    "DAWN",
    "leviathan"
  ]
}
